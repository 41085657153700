import vuedaTailwind from "@vueda/theme/vueda-tailwind/index.js";
import { patchTheme } from "@vueda/use/useTheme.js";
import cloneDeep from "lodash-es/cloneDeep.js";

export function setupVuedaTheme() {
    const pt = {};
    for (const componentName of ["FieldSetTabularInline", "FieldSetStackedInline"]) {
        pt[componentName] = {
            ...cloneDeep(vuedaTailwind[componentName]),
            title: {
                class: [...vuedaTailwind[componentName].title.class, "text-xl"],
            },
        };
    }
    patchTheme(pt);
}
