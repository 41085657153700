import { storeModelConfig } from "@vueda/stores/storeModelConfig.js";
import { getTheme } from "@vueda/use/useTheme.js";

export function setupModelConfig(pinia) {
    const modelConfigStore = storeModelConfig(pinia);

    // setup client side model config, in some cases overriding and others
    //  supplementing the server side config
    modelConfigStore.setConfig(
        { app: "tim", model: "timesheet" },
        {
            actions: [
                "list",
                "create",
                "update",
                "destroy",
                "submit",
                "approve",
                "undo-submit",
                "undo-approve",
                "reject",
            ],
        },
        {
            list: {
                fetchFields: [
                    "week_start",
                    "employee",
                    "rejected_time",
                    "as_vacation",
                    "is_active",
                    "workflow_state_code",
                    "workflow_state_name",
                ],
                displayFields: [
                    "update",
                    "week_start",
                    "employee",
                    "rejected_time",
                    "as_vacation",
                    "is_active",
                    "workflow_state",
                ],
                fieldDetails: {
                    employee: {
                        formatted: "related.employee.formatted_name",
                    },
                },
                expands: [],
            },
            create: {
                fields: ["employee", "week_start", "as_vacation"],
                expands: [],
                fieldComponents: {
                    week_start: "FieldDate",
                },
                widgetComponents: {
                    week_start: "WidgetDatePicker",
                },
                formProps: {
                    class: "max-w-prose",
                },
                fieldProps: {
                    as_vacation: {
                        help: "Prefill the timesheet with the expected level of vacation time for a week.",
                    },
                    employee: {
                        dependents: ["as_vacation"],
                    },
                },
            },
            update: {
                displayFields: [
                    "week_start",
                    "employee",
                    "overtime_gained",
                    "total_payable_hours",
                    "bank_summary",
                    "timesheet_days",
                    "timesheet_tasks",
                    "timesheet_days__start",
                    "timesheet_days__total_worked",
                    "timesheet_days__day",
                    "timesheet_days__end",
                    "timesheet_days__lunch",
                    "timesheet_tasks__project",
                    "timesheet_tasks__day",
                    "timesheet_tasks__task_code",
                    "timesheet_tasks__hours",
                    "timesheet_tasks__quantity",
                    "timesheet_tasks__description",
                    "timesheet_tasks__clone",
                    "timesheet_task_project_summaries",
                    "timesheet_task_project_summaries__project",
                    "timesheet_task_project_summaries__field_hours",
                    "timesheet_task_project_summaries__lab_hours",
                    "timesheet_task_project_summaries__office_hours",
                    "timesheet_task_project_summaries__general_hours",
                    "timesheet_task_project_summaries__leave_hours",
                    "timesheet_task_project_summaries__total_hours",
                ],
                fetchFields: [
                    "week_start",
                    "total_payable_hours",
                    "bank_summary",
                    "bank_summary_titles",
                    "employee",
                    "rejected_time",
                    "as_vacation",
                    "timesheet_days",
                    "timesheet_tasks",
                    "bank_summary",
                    "timesheet_task_project_summaries",
                ],
                computedFields: ["timesheet_days__total_worked"],
                expands: ["timesheet_days", "timesheet_tasks", "timesheet_task_project_summaries"],
                fieldComponents: {
                    timesheet_days: "FieldSetTabularInline",
                    timesheet_tasks: "FieldSetTabularInline",
                    timesheet_task_project_summaries: "FieldSetTabularInline",
                    week_start: "FieldDate",
                },
                widgetComponents: {
                    week_start: "WidgetReadOnly",
                    employee: "WidgetReadOnly",
                },
                formProps: {
                    class: "grid xl:grid-cols-3 md:grid-cols-2 gap-4",
                },
                fieldProps: {
                    as_vacation: {
                        help: "Prefill the timesheet with the expected level of vacation time for a week.",
                    },
                    timesheet_days: {
                        class: "xl:col-span-3 md:col-span-2",
                        tableBreakpoint: "inf",
                        showCreateButton: false,
                        themeOverride: {
                            ObjectsGrid: {
                                bodyRowGroup: () => {
                                    const defaultBodyRowGroup = getTheme().ObjectsGrid.bodyRowGroup;
                                    return {
                                        ...defaultBodyRowGroup,
                                        class: [
                                            {
                                                // negate the default grid classes
                                                // "sm:grid-cols-2": false,
                                                // "md:grid-cols-3": false,
                                                "lg:grid-cols-4": false,
                                                // add the new grid classes
                                                "2xs:grid-cols-2": true,
                                                "lg:grid-cols-5": true,
                                                "2xl:grid-cols-7": true,
                                            },
                                        ],
                                    };
                                },
                            },
                        },
                    },
                    timesheet_tasks: {
                        class: "xl:col-span-3 md:col-span-2",
                        tableBreakpoint: "3xl",
                        themeOverride: {
                            ObjectsGrid: {
                                bodyRowGroup: () => {
                                    const defaultBodyRowGroup = getTheme().ObjectsGrid.bodyRowGroup;
                                    return {
                                        ...defaultBodyRowGroup,
                                        class: [
                                            {
                                                // negate the default grid classes
                                                "sm:grid-cols-2": false,
                                                "md:grid-cols-3": false,
                                                "lg:grid-cols-4": false,
                                                // add the new grid classes
                                                "xl:grid-cols-2": true,
                                                "2xl:grid-cols-3": true,
                                            },
                                        ],
                                    };
                                },
                            },
                        },
                    },
                    timesheet_task_project_summaries: {
                        class: "xl:col-span-3 md:col-span-2",
                    },
                    bank_summary: {
                        class: "xl:col-span-3 md:col-span-2",
                    },
                },
                fieldDetails: {
                    timesheet_days: {
                        formatted: "related.employee.full_name",
                    },
                },
                expandDetails: {
                    timesheet_days: {
                        f: {
                            total_worked: {
                                value: "calculated.total_worked",
                                label: "Total Worked",
                            },
                        },
                        hidden: ["id", "timesheet"],
                    },
                    timesheet_tasks: {
                        f: {
                            clone: {
                                label: "Clone",
                            },
                        },
                        hidden: ["id", "timesheet"],
                    },
                    timesheet_task_project_summaries: {
                        hidden: ["id", "timesheet"],
                        extraFieldObjects: [],
                    },
                    bank_summary: {
                        f: {
                            row_name: {
                                name: "row_name",
                            },
                            sick_changes: {
                                name: "sick_changes",
                            },
                            stat_changes: {
                                name: "stat_hours",
                            },
                            toil_changes: {
                                name: "toil_changes",
                            },
                            vacation_changes: {
                                name: "vacation_changes",
                            },
                        },
                    },
                },
            },
        },
    );
}
