<script setup>
import { combineClasses } from "@arrai-innovations/reactive-helpers";
import { useLinkModelView } from "@vueda/use/useLinkModelView.js";
import omit from "lodash-es/omit.js";
import { computed, useAttrs } from "vue";
import { useRouter } from "vue-router";

import CustomizedLara from "@/CustomizedLara.js";
import FontAwesomeIconDisplay from "@/components/FontAwesomeIconDisplay.vue";
import { useIconMap } from "@/use/useIconMap.js";

defineOptions({
    inheritAttrs: false,
});
const props = defineProps({
    icon: {
        type: [String, Array],
        default: null,
    },
    verb: {
        type: String,
        default: undefined,
    },
    title: {
        type: String,
        default: undefined,
    },
    app: {
        type: String,
        default: undefined,
    },
    model: {
        type: String,
        default: undefined,
    },
    pk: {
        type: [String, Number, Array],
        default: undefined,
    },
    view: {
        type: [String, Array, Object],
        default: undefined,
    },
    to: {
        type: [String, Object],
        default: undefined,
    },
    onClick: {
        type: Function,
        default: undefined,
    },
    href: {
        type: String,
        default: undefined,
    },
    class: {
        type: [String, Array, Object],
        default: undefined,
    },
    label: {
        type: String,
        default: null,
    },
    text: {
        type: Boolean,
        default: true,
    },
    labelClass: {
        type: [String, Array, Object],
        default: undefined,
    },
    skipModelLookup: {
        type: Boolean,
        default: false,
    },
});
const router = useRouter();
const autoIcon = useIconMap(props);
const linkModelView =
    props.skipModelLookup ?
        {
            href: computed(() => props.href),
            actionDisabled: computed(() => false),
            navigate: () => {},
        }
    :   useLinkModelView(props);
const handleClick = (e) => {
    if (props.href) {
        return;
    }
    if (props.onClick) {
        e.preventDefault();
        props.onClick();
    } else if (props.to) {
        e.preventDefault();
        router.push(props.to);
    } else if (linkModelView.href.value && !linkModelView.actionDisabled.value) {
        e.preventDefault();
        linkModelView.navigate();
    }
    // let the click event propagate
};
const toHref = computed(() => {
    if (props.to) {
        return typeof props.to === "string" ? props.to : router.resolve(props.to).href;
    }
    return undefined;
});
const combinedHref = computed(() => props.href || toHref.value || linkModelView.href.value);
// getting the iconProps is a workaround, it seems like PrimeVue should be giving us the iconProps in the slotProps
const attrs = useAttrs();
const primeVueButtonPropDefaults = {
    // hard-coded defaults for the Button
    iconPos: "left",
    loading: false,
    link: true,
    raised: false,
    text: true, // we want the button text to be based on severity
    outlined: false,
    plain: false,
    size: null,
    severity: null,
};
const combinedProps = computed(() => {
    return {
        ...primeVueButtonPropDefaults,
        ...attrs,
        ...props,
    };
});
const iconProps = computed(() =>
    CustomizedLara.value.button.icon({
        props: combinedProps.value,
    }),
);
const buttonProps = computed(() => {
    return CustomizedLara.value.button.root({
        props: combinedProps.value,
        context: {
            disabled: linkModelView.actionDisabled.value || attrs.disabled,
        },
        parent: {
            instance: {
                // as long as this isn't InputGroup, it shouldn't matter
                $name: "a",
            },
        },
    });
});
const labelProps = computed(() => {
    return CustomizedLara.value.button.label({
        props: combinedProps.value,
    });
});
const combinedRootClass = computed(() => combineClasses(props.class, buttonProps.value.class));
const combinedLabelClass = computed(() => combineClasses(props.labelClass, labelProps.value.class));
</script>

<template>
    <a
        :class="combinedRootClass"
        :href="combinedHref"
        v-tooltip="title"
        @click="handleClick"
        v-bind="omit($attrs, 'text')"
    >
        <span :class="iconProps.class" v-if="autoIcon">
            <font-awesome-icon-display :icon="autoIcon" />
        </span>
        <span :class="combinedLabelClass">{{ label }}</span>
    </a>
</template>
