import { library } from "@arrai-innovations/fontawesome-svg-core";
import {
    faBalanceScale,
    faBell,
    faBook,
    faBuilding,
    faCalendarCheck,
    faChartLine,
    faCheckDouble,
    faChevronsDown,
    faChevronsLeft,
    faChevronsRight,
    faChevronsUp,
    faClipboardCheck,
    faClipboardList,
    faClock,
    faClone,
    faCog,
    faCogs,
    faCreditCard,
    faDivide,
    faDown,
    faEnvelopeOpenText,
    faEraser,
    faExchangeAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFileAlt,
    faFileCheck,
    faFileExclamation,
    faFileInvoice,
    faFilter,
    faFilterSlash,
    faFolderOpen,
    faGlassesRound,
    faGrid2Plus,
    faHandHoldingUsd,
    faHome,
    faMagnifyingGlass,
    faMoneyCheck,
    faMoon,
    faPaperPlane,
    faPencil,
    faPlaneDeparture,
    faProjectDiagram,
    faReceipt,
    faRectangleHistory,
    faRoute,
    faSave,
    faSignIn,
    faSignOut,
    faSquarePlus,
    faStopwatch,
    faSun,
    faTableList,
    faTachometerAlt,
    faTag,
    faTally,
    faTasks,
    faTimes,
    faTrashCan,
    faTruck,
    faUmbrellaBeach,
    faUp,
    faUpDown,
    faUser,
    faUserPlus,
    faUserTie,
    faWandMagicSparkles,
    faWeight,
} from "@arrai-innovations/pro-duotone-svg-icons";
import { getCRUDName } from "@vueda/utils/crudSupport.js";
import { computed, watch } from "vue";
import { deepUnref } from "vue-deepunref";

const iconOverrides = {
    [getCRUDName({ app: "tim", model: "timesheet", view: "list" })]: faCalendarCheck,
    [getCRUDName({ app: "tim", model: "timesheettask", view: "list" })]: faStopwatch,

    [getCRUDName({ app: "proj", model: "project", view: "list" })]: faFolderOpen,

    [getCRUDName({ app: "org", model: "organization", view: "list" })]: faBuilding,

    [getCRUDName({ app: "inv", model: "invoice", view: "list" })]: faFileInvoice,
    [getCRUDName({ app: "inv", model: "deliverabletest", view: "list" })]: faFileAlt,
    [getCRUDName({ app: "inv", model: "densityresulttest", view: "list" })]: faTachometerAlt,
    [getCRUDName({ app: "inv", model: "time", view: "list" })]: faClock,
    [getCRUDName({ app: "inv", model: "timeqty", view: "list" })]: faTally,
    [getCRUDName({ app: "inv", model: "trip", view: "list" })]: faRoute,
    [getCRUDName({ app: "inv", model: "projectwithinvoiceabletime", view: "list" })]: faProjectDiagram,

    [getCRUDName({ app: "bdq", model: "sendqueue", view: "list" })]: faPaperPlane,
    [getCRUDName({ app: "bdq", model: "sentitem", view: "list" })]: faRectangleHistory,

    [getCRUDName({ app: "inv", model: "disbursement", view: "list" })]: faHandHoldingUsd,

    [getCRUDName({ app: "message", model: "correspondence", view: "list" })]: faEnvelopeOpenText,

    [getCRUDName({ app: "statements", model: "outstandingclient", view: "list" })]: faExclamationTriangle,
    [getCRUDName({ app: "statements", model: "outstandingproject", view: "list" })]: faExclamationCircle,
    [getCRUDName({ app: "statements", model: "outstandinginvoice", view: "list" })]: faFileExclamation,
    [getCRUDName({ app: "statements", model: "collectionnotification", view: "list" })]: faBell,
    [getCRUDName({ app: "statements", model: "cheque", view: "list" })]: faMoneyCheck,
    [getCRUDName({ app: "statements", model: "clientcredit", view: "list" })]: faCreditCard,
    [getCRUDName({ app: "statements", model: "creditsplit", view: "list" })]: faDivide,
    [getCRUDName({ app: "statements", model: "writeoff", view: "list" })]: faEraser,
    [getCRUDName({ app: "statements", model: "transaction", view: "list" })]: faExchangeAlt,
    [getCRUDName({ app: "statements", model: "accountentry", view: "list" })]: faClipboardList,
    [getCRUDName({ app: "statements", model: "account", view: "list" })]: faBook,
    [getCRUDName({ app: "statements", model: "statementitem", view: "list" })]: faReceipt,
    [getCRUDName({ app: "statements", model: "requestdatatask", view: "list" })]: faTasks,

    // Lab tests and dispatch spans apps
    [getCRUDName({ app: "unit", model: "unitratetest", view: "list" })]: faBalanceScale,
    [getCRUDName({ app: "tim", model: "dispatchentry", view: "list" })]: faTruck,
    [getCRUDName({ app: "dens", model: "fieldcompactiontestresult", view: "list" })]: faClipboardCheck,
    [getCRUDName({ app: "dens", model: "proctorvalue", view: "list" })]: faWeight,
    [getCRUDName({ app: "dens", model: "testresultsheet", view: "list" })]: faFileCheck,
    [getCRUDName({ app: "rate", model: "rateschedule", view: "list" })]: faTag,
    [getCRUDName({ app: "empl", model: "employee", view: "list" })]: faUserTie,
    [getCRUDName({ app: "hr", model: "holiday", view: "list" })]: faUmbrellaBeach,
    [getCRUDName({ app: "hr", model: "vacationbreakpoint", view: "list" })]: faPlaneDeparture,
    [getCRUDName({ app: "hr", model: "hrsettings", view: "list" })]: faCogs,
    [getCRUDName({ app: "hr", model: "hrreport", view: "list" })]: faChartLine,
};

const verbLookup = {
    addFilter: faFilter,
    approve: faCheckDouble,
    clearFilters: faFilterSlash,
    clone: faClone,
    collapseDown: faChevronsDown,
    collapseLeft: faChevronsLeft,
    collapsedDown: faChevronsUp,
    collapsedLeft: faChevronsRight,
    create: faSquarePlus,
    createInline: faGrid2Plus,
    deactivate: faTrashCan,
    delete: faTrashCan,
    destroy: faTrashCan,
    down: faDown,
    externalLink: faExternalLink,
    generate: faWandMagicSparkles,
    goHome: faHome,
    list: faTableList,
    partial_update: faPencil,
    read: faGlassesRound,
    reject: faTimes,
    removeFilter: faFilterSlash,
    retrieve: faGlassesRound,
    save: faSave,
    search: faMagnifyingGlass,
    settings: faCog,
    showProfile: faUser,
    signIn: faSignIn,
    signOut: faSignOut,
    signUp: faUserPlus,
    submit: faPaperPlane,
    toggleDarkModeDark: faMoon,
    toggleDarkModeLight: faSun,
    toggleVisibilityHide: faEyeSlash,
    toggleVisibilityShow: faEye,
    "undo-approve": faCheckDouble,
    "undo-submit": faTimes,
    up: faUp,
    upDown: faUpDown,
    update: faPencil,
};

library.add(...Object.values(verbLookup), ...Object.values(iconOverrides));

/**
 * @typedef {{
 *     app?: string,
 *     model?: string,
 *     view?: string,
 *     bulk?: boolean,
 *     icon?: import("@arrai-innovations/fontawesome-svg-core").FontAwesomeIconDefinition,
 *     verb?: string,
 * }} IconMapProps
 */

/**
 * A composition function for getting a computed icon from a verb,
 *  an app/model/view combination, or specified directly.
 *
 * @param {IconMapProps} props - The props to use to determine the icon.
 * @returns {import('vue').ComputedRef<import("@arrai-innovations/fontawesome-svg-core").FontAwesomeIconDefinition>} - The computed icon.
 */
export function useIconMap(props) {
    const crudName = computed(() => {
        if (props.app === undefined || props.model === undefined || props.view === undefined) {
            return undefined;
        }
        return getCRUDName({
            app: props.app,
            model: props.model,
            view: props.view,
            bulk: props.bulk,
        });
    });
    const verb = computed(() => props.verb || props.view);

    const computedIcon = computed(() => props.icon || iconOverrides[crudName.value] || verbLookup[verb.value]);

    watch(computedIcon, (newIcon) => {
        // warn if passing a verb that doesn't have an icon
        //  or a crudName (app/model/view combination) that doesn't have an icon
        if (!newIcon) {
            if (!props.app || !props.model || (!props.view && !props.verb && !props.icon)) {
                console.warn("Icon map could not determine icon. props:", deepUnref(props));
            } else if (crudName.value) {
                console.warn(`No icon found for crud name: ${crudName.value}`);
            } else if (verb.value) {
                console.warn(`No icon found for verb: ${verb.value}`);
            }
            // it's harder to tell if icon is valid
        }
    });

    return computedIcon;
}
