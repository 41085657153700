<script setup>
import { useLinkModelView } from "@vueda/use/useLinkModelView.js";
import Button from "primevue/button";
import { computed, useAttrs } from "vue";
import { useRouter } from "vue-router";

import CustomizedLara from "@/CustomizedLara.js";
import FontAwesomeIconDisplay from "@/components/FontAwesomeIconDisplay.vue";
import { useIconMap } from "@/use/useIconMap.js";

const props = defineProps({
    icon: {
        type: [String, Array],
        default: undefined,
    },
    verb: {
        type: String,
        default: undefined,
    },
    title: {
        type: String,
        default: undefined,
    },
    app: {
        type: String,
        default: undefined,
    },
    model: {
        type: String,
        default: undefined,
    },
    pk: {
        type: [String, Number, Array],
        default: undefined,
    },
    view: {
        type: [String, Array, Object],
        default: undefined,
    },
    to: {
        type: [String, Object],
        default: undefined,
    },
    onClick: {
        type: Function,
        default: undefined,
    },
    text: {
        type: Boolean,
        default: false,
    },
    pt: {
        type: Object,
        default: undefined,
    },
});
const router = useRouter();
const autoIcon = useIconMap(props);
const linkModelView = useLinkModelView(props);
const handleClick = (e) => {
    if (props.onClick) {
        e.preventDefault();
        props.onClick();
    } else if (props.to) {
        e.preventDefault();
        router.push(props.to);
    } else if (linkModelView.href.value && !linkModelView.actionDisabled.value) {
        e.preventDefault();
        linkModelView.navigate();
    }
    // let the click event propagate
};
// getting the iconProps is a workaround, it seems like PrimeVue should be giving us the iconProps in the slotProps
const attrs = useAttrs();
const iconProps = computed(() => {
    const myProps = {
        ...{
            // hard-coded defaults for the Button
            iconPos: "left",
            loading: false,
            link: false,
            raised: false,
            text: false,
            outlined: false,
            plain: false,
            size: null,
            severity: null,
        },
        ...attrs,
    };
    if (props.pt && props.pt.icon) {
        return props.pt.icon({
            props: myProps,
        });
    } else {
        return CustomizedLara.value.button.icon({
            props: myProps,
        });
    }
});
</script>

<template>
    <Button v-tooltip="title" @click="handleClick" :pt="pt">
        <template v-if="autoIcon" #icon>
            <span :class="iconProps.class">
                <font-awesome-icon-display :icon="autoIcon" />
            </span>
        </template>
    </Button>
</template>
