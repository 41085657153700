import Lara from "@vueda/theme/primevue-tailwind-presets/lara/index.js";
import isObject from "lodash-es/isObject.js";
import { usePassThrough } from "primevue/passthrough";

const CustomizedLara = usePassThrough(
    Lara,
    {
        progressspinner: {
            root: {
                class: ["relative", "mx-1", "w-[1em]", "h-[1em]", "inline-block", "before:block", "before:pt-full"],
            },
        },
        button: {
            ...Lara.button,
            root: ({ props, context, parent }) => {
                const defaultRoot = Lara.button.root({ props, context, parent });
                defaultRoot.class = defaultRoot.class.map((className) => {
                    if (isObject(className)) {
                        const defaultNoLabelWithIconRule = "w-12 px-0 py-3";
                        if (defaultNoLabelWithIconRule in className) {
                            // keep y padding the same for icons based on size, not based on the lack of label.
                            className["w-12 px-0"] = className[defaultNoLabelWithIconRule];
                            delete className[defaultNoLabelWithIconRule];
                        }
                        const defaultLinkButtonRule = "text-primary-600 bg-transparent border-transparent";
                        if (defaultLinkButtonRule in className) {
                            // Link Button
                            // remove color to let severity + text=true determine color
                            className["bg-transparent dark:bg-transparent border-transparent dark:border-transparent"] =
                                className[defaultLinkButtonRule];
                            delete className[defaultLinkButtonRule];
                        }
                        const defaultPrimaryTextButtonRule = "text-primary";
                        if (defaultPrimaryTextButtonRule in className) {
                            // Primary Text Button
                            // override the global a:hover to maintain the primary color
                            // same for dark & dark hover
                            className["text-primary dark:text-primary hover:text-primary dark:hover:text-primary"] =
                                className[defaultPrimaryTextButtonRule];
                            delete className[defaultPrimaryTextButtonRule];
                        }
                        const defaultSecondaryTextButtonRule = "text-surface-500 dark:text-surface-300";
                        if (defaultSecondaryTextButtonRule in className) {
                            // Secondary Text Button
                            // override the global a:hover to maintain the secondary color
                            className[
                                "text-surface-500 dark:text-surface-300 hover:text-surface-500 dark:hover:text-surface-300"
                            ] = className[defaultSecondaryTextButtonRule];
                            delete className[defaultSecondaryTextButtonRule];
                        }
                        const defaultSuccessTextButtonRule = "text-green-500 dark:text-green-400";
                        if (defaultSuccessTextButtonRule in className) {
                            // Success Text Button
                            // override the global a:hover to maintain the success color
                            className[
                                "text-green-500 dark:text-green-400 hover:text-green-500 dark:hover:text-green-400"
                            ] = className[defaultSuccessTextButtonRule];
                            delete className[defaultSuccessTextButtonRule];
                        }
                        const defaultInfoTextButtonRule = "text-blue-500 dark:text-blue-400";
                        if (defaultInfoTextButtonRule in className) {
                            // Info Text Button
                            // override the global a:hover to maintain the info color
                            className["text-blue-500 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-400"] =
                                className[defaultInfoTextButtonRule];
                            delete className[defaultInfoTextButtonRule];
                        }
                        const defaultWarningTextButtonRule = "text-orange-500 dark:text-orange-400";
                        if (defaultWarningTextButtonRule in className) {
                            // Warning Text Button
                            // override the global a:hover to maintain the warning color
                            className[
                                "text-orange-500 dark:text-orange-400 hover:text-orange-500 dark:hover:text-orange-400"
                            ] = className[defaultWarningTextButtonRule];
                            delete className[defaultWarningTextButtonRule];
                        }
                        const defaultHelpTextButtonRule = "text-purple-500 dark:text-purple-400";
                        if (defaultHelpTextButtonRule in className) {
                            // Help Text Button
                            // override the global a:hover to maintain the help color
                            className[
                                "text-purple-500 dark:text-purple-400 hover:text-purple-500 dark:hover:text-purple-400"
                            ] = className[defaultHelpTextButtonRule];
                            delete className[defaultHelpTextButtonRule];
                        }
                        const defaultDangerTextButtonRule = "text-red-500 dark:text-red-400";
                        if (defaultDangerTextButtonRule in className) {
                            // Danger Text Button
                            // override the global a:hover to maintain the danger color
                            className["text-red-500 dark:text-red-400 hover:text-red-500 dark:hover:text-red-400"] =
                                className[defaultDangerTextButtonRule];
                            delete className[defaultDangerTextButtonRule];
                        }
                        const defaultContrastTextButtonRule = "text-surface-900 dark:text-surface-0";
                        if (defaultContrastTextButtonRule in className) {
                            // Contrast Text Button
                            // override the global a:hover to maintain the contrast color
                            className[
                                "text-surface-900 dark:text-surface-0 hover:text-surface-900 dark:hover:text-surface-0"
                            ] = className[defaultContrastTextButtonRule];
                            delete className[defaultContrastTextButtonRule];
                        }
                        const defaultPrimaryTextHoverBgRule = "hover:bg-primary-300/20";
                        if (defaultPrimaryTextHoverBgRule in className) {
                            // Primary Hover Background
                            // maintain the primary hover background color for dark mode
                            className["hover:bg-primary-300/20 dark:hover:bg-primary-300/20"] =
                                className[defaultPrimaryTextHoverBgRule];
                            delete className[defaultPrimaryTextHoverBgRule];
                        }
                        const defaultSecondaryTextHoverBgRule = "hover:bg-surface-300/20";
                        if (
                            defaultSecondaryTextHoverBgRule in className &&
                            // there are multiple rules applying this...
                            className[defaultSecondaryTextHoverBgRule] ===
                                ((props.text || props.outlined) && props.severity === "secondary" && !props.plain)
                        ) {
                            // Secondary Hover Background
                            // maintain the secondary hover background color for dark mode
                            className["hover:bg-surface-300/20 dark:hover:bg-surface-300/20"] =
                                className[defaultSecondaryTextHoverBgRule];
                            delete className[defaultSecondaryTextHoverBgRule];
                        }
                        const defaultSuccessTextHoverBgRule = "hover:bg-green-300/20";
                        if (defaultSuccessTextHoverBgRule in className) {
                            // Success Hover Background
                            // maintain the success hover background color for dark mode
                            className["hover:bg-green-300/20 dark:hover:bg-green-300/20"] =
                                className[defaultSuccessTextHoverBgRule];
                            delete className[defaultSuccessTextHoverBgRule];
                        }
                        const defaultInfoTextHoverBgRule = "hover:bg-blue-300/20";
                        if (defaultInfoTextHoverBgRule in className) {
                            // Info Hover Background
                            // maintain the info hover background color for dark mode
                            className["hover:bg-blue-300/20 dark:hover:bg-blue-300/20"] =
                                className[defaultInfoTextHoverBgRule];
                            delete className[defaultInfoTextHoverBgRule];
                        }
                        const defaultWarningTextHoverBgRule = "hover:bg-orange-300/20";
                        if (defaultWarningTextHoverBgRule in className) {
                            // Warning Hover Background
                            // maintain the warning hover background color for dark mode
                            className["hover:bg-orange-300/20 dark:hover:bg-orange-300/20"] =
                                className[defaultWarningTextHoverBgRule];
                            delete className[defaultWarningTextHoverBgRule];
                        }
                        const defaultHelpTextHoverBgRule = "hover:bg-purple-300/20";
                        if (defaultHelpTextHoverBgRule in className) {
                            // Help Hover Background
                            // maintain the help hover background color for dark mode
                            className["hover:bg-purple-300/20 dark:hover:bg-purple-300/20"] =
                                className[defaultHelpTextHoverBgRule];
                            delete className[defaultHelpTextHoverBgRule];
                        }
                        const defaultDangerTextHoverBgRule = "hover:bg-red-300/20";
                        if (defaultDangerTextHoverBgRule in className) {
                            // Danger Hover Background
                            // maintain the danger hover background color for dark mode
                            className["hover:bg-red-300/20 dark:hover:bg-red-300/20"] =
                                className[defaultDangerTextHoverBgRule];
                            delete className[defaultDangerTextHoverBgRule];
                        }
                        const defaultContrastTextHoverBgRule =
                            "hover:bg-surface-900/10 dark:hover:bg-[rgba(255,255,255,0.03)]";
                        if (defaultContrastTextHoverBgRule in className) {
                            // Contrast Hover Background
                            // .03 isn't enough for my old-man eyes, which seems like the point of contrast text...
                            className["hover:bg-surface-900/10 dark:hover:bg-surface-0/10"] =
                                className[defaultContrastTextHoverBgRule];
                            delete className[defaultContrastTextHoverBgRule];
                        }
                    }
                    return className;
                });
                // allow the group-hover for underlining button labels when we are a link
                defaultRoot.class.push("group");
                return defaultRoot;
            },
            label: ({ props }) => {
                const defaultLabel = Lara.button.label({ props });
                defaultLabel.class = defaultLabel.class.map((className) => {
                    if (isObject(className)) {
                        // switch hover:underline key to group-hover:underline
                        // usually links have underline in our site, but for nav we don't
                        if ("hover:underline" in className) {
                            className["group-hover:underline"] = className["hover:underline"];
                            delete className["hover:underline"];
                        }
                    }
                    return className;
                });
                return defaultLabel;
            },
        },
        dialog: {
            ...Lara.dialog,
            title: {
                class: ["font-bold leading-relaxed text-xl"],
            },
        },
        message: {
            ...Lara.message,
            root: ({ props }) => {
                const defaultRoot = Lara.message.root({ props });
                // add 'help' severity, like button has.
                defaultRoot.class.push({
                    "bg-purple-100/70 dark:bg-purple-500/20": props.severity === "help",
                    "outline-purple-500 dark:outline-purple-400": props.severity === "help",
                    "text-purple-700 dark:text-purple-300": props.severity === "help",
                });
                return defaultRoot;
            },
            wrapper: {
                // less padding.
                class: ["flex items-center", "py-2 px-4"],
            },
        },
        toast: {
            ...Lara.toast,
            message: ({ props }) => {
                const defaultMessage = Lara.toast.message({ props });
                // add 'help' severity, like button has.
                defaultMessage.class.push({
                    "bg-purple-100/70 dark:bg-purple-500/20": props.message.severity === "help",
                    "border-purple-500 dark:border-purple-400": props.message.severity === "help",
                    "text-purple-700 dark:text-purple-300": props.message.severity === "help",
                });
                return defaultMessage;
            },
        },
        directives: {
            ...Lara.directives,
            tooltip: {
                ...Lara.directives.tooltip,
                root: (...args) => {
                    const defaultRoot = Lara.directives.tooltip.root(...args);
                    defaultRoot.class = defaultRoot.class.map((className) => {
                        if (className === "shadow-md") {
                            return "";
                        }
                        return className;
                    });
                    return defaultRoot;
                },
                arrow: (...args) => {
                    const defaultArrow = Lara.directives.tooltip.arrow(...args);
                    // the default Lara for tooltip is broken WRT shadows, arrow positioning and not flipping based
                    //  on context direction
                    // https://github.com/primefaces/primevue/issues/6095
                    defaultArrow.class = defaultArrow.class.map((className) => {
                        const isDefaultContextDirection =
                            !args[0].context?.right &&
                            !args[0].context?.left &&
                            !args[0].context?.top &&
                            !args[0].context?.bottom;
                        if (isObject(className)) {
                            if ("border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-surface-600" in className) {
                                return {
                                    "border-y-[0.25rem]":
                                        args[0].context?.left || args[0].context?.right || isDefaultContextDirection,
                                    "border-l-[0.25rem]": args[0].context?.left,
                                    "border-r-[0.25rem]": args[0].context?.right || isDefaultContextDirection,
                                    "border-x-[0.25rem]": args[0].context?.top || args[0].context?.bottom,
                                    "border-t-[0.25rem]": args[0].context?.top,
                                    "border-b-[0.25rem]": args[0].context?.bottom,
                                    "border-l-0": args[0].context?.right || isDefaultContextDirection,
                                    "border-r-0": args[0].context?.left,
                                    "border-b-0": args[0].context?.top,
                                    "border-t-0": args[0].context?.bottom,
                                    "border-r-surface-600 dark:border-r-surface-700":
                                        args[0].context?.right || isDefaultContextDirection,
                                    "border-l-surface-600 dark:border-l-surface-700": args[0].context?.left,
                                    "border-t-surface-600 dark:border-t-surface-700": args[0].context?.top,
                                    "border-b-surface-600 dark:border-b-surface-700": args[0].context?.bottom,
                                };
                            }
                            if ("-mt-1" in className) {
                                return {
                                    "-mt-1":
                                        args[0].context?.left || args[0].context?.right || isDefaultContextDirection,
                                    "-ml-1": args[0].context?.top || args[0].context?.bottom,
                                };
                            }
                        }
                        return className;
                    });
                    defaultArrow.class.push("shadow-md");
                    return defaultArrow;
                },
                text: {
                    class: [...Lara.directives.tooltip.text.class, "shadow-md"],
                },
            },
        },
    },
    {
        mergeSections: true,
        mergeProps: false,
    },
);

export default CustomizedLara;
